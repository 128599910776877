import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  ModalMissingInformation,
  ExternalLink,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import css from './Topbar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Menu from '../Menu/Menu';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import { Link } from 'react-router-dom';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.switchLang = this.switchLang.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  switchLang(lang) {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('lang', lang);
      window.location.reload();
    }
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
    } = this.props;

    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        history={this.props.history}
      />
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const classes = classNames(rootClassName || css.root, className);
    let lang = 'PL';
    if (typeof window !== 'undefined') {
      lang = window.localStorage.getItem('lang');
    }
    const langMenu = (
      <Menu>
        <MenuLabel className={css.LinkMenuLabel} isOpenClassName={css.LinkMenuIsOpen}>
          <span className={css.selectedLangLabel}>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 19C14.7309 19 19 14.7306 19 9.5C19 4.26906 14.7306 0 9.5 0C4.2691 0 0 4.2694 0 9.5C0 14.7309 4.2694 19 9.5 19ZM12.3183 17.399C12.8566 16.7112 13.2692 15.8908 13.5791 15.066H15.7673C14.8401 16.1092 13.6575 16.9197 12.3183 17.399ZM16.6036 13.9531H13.939C14.2675 12.7586 14.4602 11.4343 14.5012 10.0566H17.8678C17.774 11.4812 17.323 12.8096 16.6036 13.9531ZM16.6036 5.04688C17.323 6.1904 17.774 7.5188 17.8678 8.94336H14.5012C14.4602 7.56571 14.2675 6.24135 13.939 5.04688H16.6036ZM15.7673 3.93359H13.5791C13.2693 3.10958 12.8567 2.28905 12.3183 1.60101C13.6575 2.08028 14.8401 2.89082 15.7673 3.93359ZM10.0566 1.2085C11.149 1.57874 11.9326 2.87787 12.3808 3.93359H10.0566V1.2085ZM10.0566 5.04688H12.7815C13.1354 6.21871 13.3436 7.54816 13.3875 8.94336H10.0566V5.04688ZM10.0566 10.0563H13.3875C13.3436 11.4518 13.1354 12.7813 12.7815 13.9531H10.0566V10.0563ZM10.0566 15.066H12.3808C11.9319 16.1239 11.1481 17.4216 10.0566 17.7915V15.066ZM3.23275 15.0664H5.42094C5.73073 15.8904 6.14327 16.7109 6.68169 17.399C5.34253 16.9198 4.15992 16.1092 3.23275 15.0664ZM8.94336 17.7915C7.85194 17.4216 7.06819 16.1241 6.61916 15.0664H8.94336V17.7915ZM8.94336 13.9531H6.21853C5.86458 12.7813 5.65636 11.4518 5.61253 10.0566H8.94336V13.9531ZM8.94336 8.94336H5.61253C5.65636 7.54816 5.86458 6.21871 6.21853 5.04688H8.94336V8.94336ZM8.94336 1.2085V3.93359H6.61916C7.06815 2.87605 7.85186 1.57845 8.94336 1.2085ZM6.68165 1.60101C6.14338 2.28883 5.73084 3.10921 5.4209 3.93359H3.23275C4.15992 2.89082 5.34253 2.08024 6.68165 1.60101ZM2.39641 5.0465H5.06101C4.73252 6.24135 4.53981 7.56571 4.49884 8.94336H1.13221C1.22602 7.5188 1.67697 6.1904 2.39641 5.0465ZM1.13221 10.0566H4.49884C4.53981 11.4343 4.73252 12.7586 5.06101 13.9531H2.39641C1.67697 12.8096 1.22602 11.4812 1.13221 10.0566Z"
                fill="black"
              />
            </svg>{' '}
            &nbsp;
            {lang ? lang : 'PL'}
          </span>
        </MenuLabel>
        <MenuContent className={css.LinkMenuContent} isFromTopbar={true}>
          <MenuItem key="ProfileSettingsPage">
            <Link className={css.langOptionLink} onClick={() => this.switchLang('PL')}>
              <span className={css.menuItemBorder} />
              PL
            </Link>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <Link className={css.langOptionLink} onClick={() => this.switchLang('EN')}>
              <span className={css.menuItemBorder} />
              EN
            </Link>
          </MenuItem>
        </MenuContent>
      </Menu>
    );

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
          <div className={css.centerSection}>
            <NamedLink
              className={css.home}
              name="LandingPage"
              title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            >
              <Logo format="mobile" />
            </NamedLink>
          </div>
          <div className={css.linkMobileWrapper}>
            {currentPage !== 'LandingPage' && currentPage !== 'EditListingPage' ? (
              <Button
                rootClassName={css.searchMenu}
                onClick={this.handleMobileSearchOpen}
                title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {/* <SearchIcon className={css.searchMenuIcon} /> */}
              </Button>
            ) : (
              <div className={css.searchMenu}></div>
            )}
            {langMenu}
          </div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            history={this.props.history}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
